import React from 'react';
import { BottomNavigation, Box, type SxProps, type Theme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { loginShowDialog } from '@travel-platform/commons/src/redux/login-slice';
import { useLanguage } from '@/components/common/language/language';
import WhatsappMessenger from '@/components/common/whatsapp-messenger/whatsapp-messenger';
import { Routes } from '@/components/common/routes/routes';
import { BOOKING_URL } from '@/components/common/constant/constant';
import { NavbarItem, type NavbarItemProps } from '@/components/common/navbar';
import { useRouter } from 'next/router';

const bottomNavigation: SxProps<Theme> = {
  position: 'fixed',
  left: 0,
  right: 0,
  width: '100%',
  boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.04)',
  zIndex: '3',
  padding: '12px 16px',
  height: 'auto !important',
  display: 'flex',
  justifyContent: 'space-around !important',
  transition: 'all 0.5s',
};

export const Navbar: React.FC = () => {
  // Use Hook
  const dispatch = useDispatch();
  const languageJson = useLanguage();
  const router = useRouter();

  // Constant
  const navigationItems: NavbarItemProps[] = [
    {
      icon: 'icon-profile-circle-active',
      url: BOOKING_URL + '/' + router.locale + Routes.Dashboard,
      text: languageJson.common.profile,
      needsLogin: true,
      showLoginModal: () => dispatch(loginShowDialog(true)),
    },
    {
      icon: 'icon-note',
      url: BOOKING_URL + '/' + router.locale + Routes.DashboardTrackOrder,
      text: languageJson.common.my_order,
      needsLogin: true,
      showLoginModal: () => dispatch(loginShowDialog(true)),
    },
    {
      icon: 'icon-shopping-cart',
      url: Routes.TravelersService,
      text: languageJson.common.cart,
    },
  ];

  return (
    <React.Fragment>
      <Box
        position='fixed'
        bottom={80}
        zIndex={3}
        right={20}
        sx={{
          cursor: 'pointer',
          transition: 'all 0.5s',
        }}>
        <WhatsappMessenger />
      </Box>
      <BottomNavigation
        sx={{
          ...bottomNavigation,
          bottom: 0,
        }}
        showLabels
        value={0}>
        {navigationItems.map((navigationItem, index) => (
          <NavbarItem
            key={index}
            needsLogin={navigationItem.needsLogin}
            showLoginModal={navigationItem.showLoginModal}
            icon={navigationItem.icon}
            url={navigationItem.url}
            text={navigationItem.text}
          />
        ))}
      </BottomNavigation>
    </React.Fragment>
  );
};
