import type {
  DocDeleteResponse,
  DocDownloadResponse,
  DocUploadResponse,
  GetListUserPassengersRequest,
  GetListUserPassengersResponse,
  GetProfileResponse,
  SubmitPassengerInformationBody,
  SubmitPassengerInformationResponse,
} from '@/services';
import { type CancelToken } from 'axios';
import { Api, Method } from '@travel-platform/commons/src/services/api';
import { type AxiosReturnType } from '../api';

export const apiGetListUserPassengers = (
  params: GetListUserPassengersRequest,
  token: string | null,
  cancelToken?: CancelToken
): AxiosReturnType<GetListUserPassengersResponse> => {
  return Api<GetListUserPassengersResponse>(
    Method.GET,
    '/authentication/passenger/?',
    params,
    null,
    cancelToken,
    token
  );
};
export const apiSubmitPassengerInformation = (
  body: SubmitPassengerInformationBody,
  token: string | null
) => {
  return Api<SubmitPassengerInformationResponse[]>(
    Method.PUT,
    '/authentication/passenger/',
    null,
    body,
    undefined,
    token
  );
};
export const apiGetProfile = (token: string | null): AxiosReturnType<GetProfileResponse> => {
  return Api<GetProfileResponse>(
    Method.GET,
    '/authentication/auth/profile?',
    null,
    null,
    undefined,
    token
  );
};
export const apiDocUpload = (
  body: FormData,
  token: string | null,
  cancelToken: CancelToken
): AxiosReturnType<DocUploadResponse> => {
  return Api<DocUploadResponse>(
    Method.POST,
    '/authentication/passenger/document/upload',
    null,
    body,
    cancelToken,
    token
  );
};
export const apiDocDownload = (
  id: number,
  token: string | null
): AxiosReturnType<DocDownloadResponse> => {
  return Api<DocDownloadResponse>(
    Method.GET,
    `/authentication/passenger/document/${id}`,
    null,
    null,
    undefined,
    token
  );
};
export const apiDocDelete = (
  id: number,
  token: string | null
): AxiosReturnType<DocDeleteResponse> => {
  return Api<DocDeleteResponse>(
    Method.DELETE,
    `/authentication/passenger/document/${id}`,
    null,
    null,
    undefined,
    token
  );
};
