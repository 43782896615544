export enum Routes {
  Root = '/',
  Login = '/login',
  Dashboard = '/dashboard',
  Blog = '/blog/',
  Diaries = '/blog/iran-diaries/',
  Tours = '/tours',
  Youtube = 'https://www.youtube.com/@visitouriran',
  Twitter = 'https://twitter.com/VisitOurIran/',
  Instagram = 'https://www.instagram.com/visitouriran/',
  Facebook = 'https://www.facebook.com/visitouriran/',
  HomeDiariesVideoIframe = 'https://www.youtube.com/embed/78hwCn1cRaM',
  TravelersService = '/travel-pack',
  ServiceIranVisa = '/iran-visa',
  ServiceInsurance = '/iran-travel-insurance',
  ServiceDebitCard = '/iran-travel-debit-card',
  ServiceTransfer = '/iran-transfer',
  ServiceSimCard = '/sim-card',
  ServiceCarRental = '/car-rental',
  ServiceEssentialTravelersPackage = '/essential-travelers-package',
  TravelStyleInDepthCultural = '/tours/iran-in-depth-cultural-tours',
  TravelStylePilgrimage = '/tours/iran-ziyarat-tours',
  TravelStyleAdventureNature = '/tours/iran-adventure-and-nature-tours',
  TravelStyleNomads = '/tours/iran-nomad-tours',
  TravelStyleWildlife = '/tours/iran-wildlife-tours',
  TravelStyleSilkRoad = '/tours/silk-road-tours',
  TravelStyleIranMedical = '/tours/iran-medical-tours',
  TravelStyleSkiing = '/tours/iran-ski-tours',
  TravelStyleHikingTrekking = '/tours/iran-hiking-and-trekking-tours',
  TravelStyleFoodCulinary = '/tours/iran-food-and-culinary-tours',
  TripadvisorAttractionReview = 'https://www.tripadvisor.com/Attraction_Review-g293999-d17313801-Reviews-Visit_Our_Iran-Tehran_Tehran_Province.html',
  AboutUs = '/blog/about-us/',
  ContactUs = '/blog/about-us/',
  DashboardTrackOrder = '/dashboard/track-order',
  OrederConfirm = '/order/confirm',
  Flight = '/flight',
  Hotel = '/hotel',
}
