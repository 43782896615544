import { OrganizationJsonLd } from 'next-seo';

const JsonLdOrganization = () => {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': ['TravelAgency', 'Organization'],
    name: 'VisitOurIran',
    url: 'https://www.visitouriran.com',
    logo: 'https://www.visitouriran.com/booking/logo-VISITOURIRAN-header.svg',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+982188553494',
        contactType: 'customer service',
        areaServed: 'IR',
        availableLanguage: 'English',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+989200980092',
        contactType: 'customer service',
        areaServed: 'IR',
        availableLanguage: 'English',
      },
    ],
    sameAs: [
      'https://www.instagram.com/visitouriran/',
      'https://twitter.com/VisitOurIran/',
      'https://www.youtube.com/@visitouriran',
      'https://www.facebook.com/visitouriran/',
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Vozara',
      addressLocality: 'Tehran',
      addressCountry: 'Iran',
    },
  };

  return <OrganizationJsonLd {...jsonLd} />;
};
export default JsonLdOrganization;
