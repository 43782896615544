import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import { Navbar } from '@/components/common/navbar';
import { Box } from '@mui/material';
import React, { useEffect, type ReactElement } from 'react';
import WhatsappMessenger from '../whatsapp-messenger/whatsapp-messenger';
import { EnumTokenType } from '@travel-platform/commons/src/components/common/types/index.types';
import { login, logout } from '@travel-platform/commons/src/redux/auth-slice';
import { hasCookie, getCookie } from 'cookies-next';
import { useDispatch } from 'react-redux';
import { apiGetProfile } from '@/services/authentication/authentication';
import dynamic from 'next/dynamic';
import { needRefreshToken } from '@travel-platform/commons/src/services/auth/auth';

const LoginDialog = dynamic(
  () => import('@travel-platform/commons/src/components/login').then(s => s.LoginDialog),
  {
    ssr: false,
  }
);
const GlobalSvg = dynamic(() => import('@/components/common/global-svg/global-svg'), {
  ssr: false,
});
const SnackbarAlert = dynamic(() => import('../snackbar-alert/snackbar-alert'), {
  ssr: false,
});

export type GlobalProps = {
  children: ReactElement;
};

export default function Global({ children }: GlobalProps) {
  // Use Hooks
  const isMobile = useDevice() === DeviceEnum.MOBILE;
  const dispatch = useDispatch();
  // const { auth } = useAppSelector(state => state);
  // Use Global State
  useEffect(() => {
    const hasAuthCookie = hasCookie(EnumTokenType.ACCESS_TOKEN as string);

    checkLoginStrategy();

    if (hasAuthCookie) {
      const authCookie = getCookie(EnumTokenType.ACCESS_TOKEN) as string;

      dispatch(
        login({
          auth: {
            token: authCookie,
          },
        })
      );

      (async () => {
        const { data } = await apiGetProfile(authCookie);
        dispatch(
          login({
            auth: {
              token: authCookie,
              ...data,
            },
          })
        );
      })();
    }
  }, []);

  const checkLoginStrategy = async () => {
    const resultOfCheckTokenState = await needRefreshToken();
    if (resultOfCheckTokenState.state === 'logout') {
      dispatch(logout());
    }
  };
  return (
    <React.Fragment>
      <GlobalSvg />
      <LoginDialog />
      <SnackbarAlert />
      {isMobile ? (
        <Navbar />
      ) : (
        <Box
          position='fixed'
          zIndex={2147483000}
          display='flex'
          right={{ xs: 20, md: 20 }}
          bottom={{ xs: 16, md: 16 }}
          sx={{ cursor: 'pointer' }}>
          <WhatsappMessenger />
        </Box>
      )}
      {children}
    </React.Fragment>
  );
}
