import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { CountryCodeType } from '@/components/flights/passengers-information/components/dialog-login/dialog-login.types';
import type { FlightPassengerInformationStateReduxType } from '@/services/authentication/authentication.types';

type PassengersFlightInitialState = {
  information: Record<
    string,
    {
      passengers: FlightPassengerInformationStateReduxType[];
      contacts: {
        email: string;
        mobile: string;
        countryCode: CountryCodeType;
      };
      isRuleAccepted: boolean;
    }
  > | null;
};

const initialState: PassengersFlightInitialState = {
  information: null,
};

export const passengersFlightSlice = createSlice({
  name: 'passengersFlight',
  initialState,
  reducers: {
    passengersFlightSave: (
      state,
      action: PayloadAction<Record<
        string,
        {
          passengers: FlightPassengerInformationStateReduxType[];
          contacts: {
            email: string;
            mobile: string;
            countryCode: CountryCodeType;
          };
          isRuleAccepted: boolean;
        }
      > | null>
    ) => {
      state.information = action.payload;
    },
    passengersFlightClear: state => {
      state.information = null;
    },
  },
});

export const { passengersFlightSave, passengersFlightClear } = passengersFlightSlice.actions;

export default passengersFlightSlice.reducer;
