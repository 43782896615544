import React from 'react';
import { createTheme } from '@mui/material';
import palette from '../styles/color.module.scss';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1_bold: true;
    h1_regular: true;
    h2_bold: true;
    h2_regular: true;
    h3_bold: true;
    h3_regular: true;
    t1_bold: true;
    t1_regular: true;
    t2_bold: true;
    t2_regular: true;
    t3_bold: true;
    t3_regular: true;
    b1_bold: true;
    b1_regular: true;
    b2_bold: true;
    b2_regular: true;
    c1_bold: true;
    c1_regular: true;
    c2_bold: true;
    c2_regular: true;
    c3_bold: true;
    c3_regular: true;
  }
}

type ExtendedTypographyOptionsPoppins = {
  h1_bold: React.CSSProperties;
  h1_regular: React.CSSProperties;
  h2_bold: React.CSSProperties;
  h2_regular: React.CSSProperties;
  h3_bold: React.CSSProperties;
  h3_regular: React.CSSProperties;
  t1_bold: React.CSSProperties;
  t1_regular: React.CSSProperties;
  t2_bold: React.CSSProperties;
  t2_regular: React.CSSProperties;
  t3_bold: React.CSSProperties;
  t3_regular: React.CSSProperties;
  b1_bold: React.CSSProperties;
  b1_regular: React.CSSProperties;
  b2_bold: React.CSSProperties;
  b2_regular: React.CSSProperties;
  c1_bold: React.CSSProperties;
  c1_regular: React.CSSProperties;
  c2_bold: React.CSSProperties;
  c2_regular: React.CSSProperties;
  c3_bold: React.CSSProperties;
  c3_regular: React.CSSProperties;
} & TypographyOptions;

const fontFamily: Record<'rtl' | 'ltr', string> = {
  rtl: 'NotoSansArabic',
  ltr: 'Poppins, sans-serif',
};

export const getTheme = (direction: 'rtl' | 'ltr') => {
  const theme = createTheme({
    direction,
    palette: {
      common: {
        white: palette.white,
        black: palette.black,
      },
      primary: {
        main: palette.primary,
      },
      secondary: {
        main: palette.secondary,
      },
      warning: {
        main: palette.warning,
      },
      success: {
        main: palette.success,
      },
      info: {
        main: palette.info,
      },
      error: {
        main: palette.error,
      },
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: fontFamily[direction],
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popper: {
            fontFamily: fontFamily[direction],
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            fontFamily: fontFamily[direction],
          },
        },
      },
      MuiPagination: {
        styleOverrides: {},
      },
      MuiPaginationItem: {
        styleOverrides: {
          text: {
            '&:hover': {
              backgroundColor: palette.primary_lighter_20,
            },
          },
          root: {
            borderRadius: ' 6px !important',
            '&.Mui-selected': {
              backgroundColor: palette.primary + ' !important',
              borderRadius: '6px',
              color: palette.white,
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '24px',
            },
          },
          previousNext: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& svg': {
              background: palette.primary,
              color: palette.white,
              borderRadius: '6px',
              fontSize: '26px',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            '& a': {
              textDecoration: 'none',
            },
            '&.MuiButton-text:hover': {
              backgroundColor: palette.primary_08,
              fontFamily: fontFamily[direction],
            },
          },
        },
      },
    },
    typography: {
      fontFamily: [fontFamily[direction]].join(','),
      h1_bold: {
        fontWeight: 700,
        fontSize: '96px',
        lineHeight: '144px',
        fontFamily: fontFamily[direction],
      },
      h1_regular: {
        fontWeight: 400,
        fontSize: '96px',
        lineHeight: '144px',
        fontFamily: fontFamily[direction],
      },
      h2_bold: {
        fontWeight: 700,
        fontSize: '72px',
        lineHeight: '112px',
        fontFamily: fontFamily[direction],
      },
      h2_regular: {
        fontWeight: 400,
        fontSize: '72px',
        lineHeight: '112px',
        fontFamily: fontFamily[direction],
      },
      h3_bold: {
        fontWeight: 700,
        fontSize: '48px',
        lineHeight: '72px',
        fontFamily: fontFamily[direction],
      },
      h3_regular: {
        fontWeight: 400,
        fontSize: '48px',
        lineHeight: '72px',
        fontFamily: fontFamily[direction],
      },
      t1_bold: {
        fontWeight: 700,
        fontSize: '32px',
        lineHeight: '56px',
        fontFamily: fontFamily[direction],
      },
      t1_regular: {
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '56px',
        fontFamily: fontFamily[direction],
      },
      t2_bold: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '40px',
        fontFamily: fontFamily[direction],
      },
      t2_regular: {
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '40px',
        fontFamily: fontFamily[direction],
      },
      t3_bold: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '32px',
        fontFamily: fontFamily[direction],
      },
      t3_regular: {
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '32px',
        fontFamily: fontFamily[direction],
      },
      b1_bold: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '28px',
        fontFamily: fontFamily[direction],
      },
      b1_regular: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '28px',
        fontFamily: fontFamily[direction],
      },
      b2_bold: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: fontFamily[direction],
      },
      b2_regular: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: fontFamily[direction],
      },
      c1_bold: {
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '20px',
        fontFamily: fontFamily[direction],
      },
      c1_regular: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        fontFamily: fontFamily[direction],
      },
      c2_bold: {
        fontWeight: 700,
        fontSize: '10px',
        lineHeight: '16px',
        fontFamily: fontFamily[direction],
      },
      c2_regular: {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '16px',
        fontFamily: fontFamily[direction],
      },
      c3_bold: {
        fontWeight: 700,
        fontSize: '8px',
        lineHeight: '12px',
        fontFamily: fontFamily[direction],
      },
      c3_regular: {
        fontWeight: 400,
        fontSize: '8px',
        lineHeight: '12px',
        fontFamily: fontFamily[direction],
      },
    } as ExtendedTypographyOptionsPoppins,
  });
  return theme;
};

export const getCache = (direction: 'rtl' | 'ltr') => {
  const cache = createCache({
    key: direction === 'ltr' ? 'muiltr' : 'muirtl',
    stylisPlugins: [prefixer, ...(direction === 'rtl' ? [rtlPlugin] : [])],
  });
  return cache;
};
