import { useCallback, useEffect } from 'react';
import Router from 'next/router';
import { Provider } from 'react-redux';
import { store } from '@/redux/store';
import { ThemeProvider } from '@mui/material/styles';
import '../components/common/styles/global-style.css';
import { useLanguage } from '@/components/common/language/language';
import { getTheme, getCache } from '../components/common/theme/theme';
import NProgress from 'nprogress';
import '@/components/common/styles/libraries/nprogress.scss';
import '@/components/common/styles/libraries/swiper/hero-section-swiper.scss';
import '@/components/common/styles/libraries/swiper/slider-full-pagination.scss';
import '@/components/common/styles/libraries/multi-date-picker/multi-date-picker.scss';
import '@/components/common/styles/libraries/multi-date-picker/multi-date-picker-mobile.scss';
import 'swiper/css';
import Global from '@/components/common/global/global';
import { DefaultSeo } from 'next-seo';
import config from '@/components/common/site-config';
import Script from 'next/script';
import App from 'next/app';
import { userAgentFromString } from 'next/server';
import mediaQuery from 'css-mediaquery';
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';
import JsonLdOrganization from '@/components/json-lds/organization';

NProgress.configure({ showSpinner: true, easing: 'ease', speed: 200 });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// Environment
const CHATWOOT_WEBSITE_TOKEN = process.env.NEXT_PUBLIC_CHATWOOT_WEBSITE_TOKEN || null;
const CHATWOOT_WEBSITE_URL = process.env.NEXT_PUBLIC_CHATWOOT_WEBSITE_URL || null;

type DirectionType = 'rtl' | 'ltr';

const MyApp = (props: any): JSX.Element => {
  const { Component, pageProps, userAgentValue } = props;
  const language = useLanguage();

  const ssrMatchMedia = useCallback(
    (query: any) => {
      const deviceType = userAgentFromString(userAgentValue).device.type || 'desktop';

      return {
        matches: mediaQuery.match(query, {
          width: deviceType === 'mobile' ? '0px' : '1024px',
        }),
      };
    },
    [userAgentValue]
  );
  useEffect(() => {
    document.body.setAttribute('dir', language.direction);
  }, [language.direction]);

  const dir = language.direction as DirectionType;

  return (
    <>
      <DefaultSeo {...config.seo.default} />
      <JsonLdOrganization />
      <Script
        strategy='afterInteractive'
        id='hotjar'
        type='text/javascript'>
        {`(function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </Script>
      <Script
        strategy='afterInteractive'
        id='clarity'
        type='text/javascript'>
        {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="${process.env.NEXT_PUBLIC_CLARITY_URL}"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID}");`}
      </Script>
      <Script
        id='google-manager'
        strategy='afterInteractive'>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], 
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); 
          })(window,document,'script','dataLayer','GTM-T3ZMG52');`}
      </Script>

      {!!CHATWOOT_WEBSITE_URL && !!CHATWOOT_WEBSITE_TOKEN && (
        <Script
          id='chatwoot-script'
          strategy='afterInteractive'>
          {`(function(d,t) { var BASE_URL="${CHATWOOT_WEBSITE_URL}"; var g=d.createElement(t),s=d.getElementsByTagName(t)[0]; g.src=BASE_URL+"/packs/js/sdk.js"; g.defer = true; g.async = true; s.parentNode.insertBefore(g,s); g.onload=function(){ window.chatwootSDK.run({ websiteToken: '${CHATWOOT_WEBSITE_TOKEN}', baseUrl: BASE_URL }) } })(document,"script");`}
        </Script>
      )}

      <AppCacheProvider
        {...props}
        emotionCache={getCache(dir)}>
        <ThemeProvider
          theme={{
            ...getTheme(dir),
            components: {
              ...getTheme(dir).components,
              MuiUseMediaQuery: {
                defaultProps: {
                  ssrMatchMedia,
                },
              },
            },
          }}>
          <Provider store={store}>
            <Global>
              <Component {...pageProps} />
            </Global>
          </Provider>
        </ThemeProvider>
      </AppCacheProvider>
    </>
  );
};
MyApp.getInitialProps = async (ctx: any): Promise<any> => {
  const initialProps = await App.getInitialProps(ctx);
  const userAgentValue =
    typeof window === 'undefined' ? ctx.ctx.req.headers['user-agent'] : window.navigator.userAgent;

  return { userAgentValue, ...initialProps };
};
export default MyApp;
