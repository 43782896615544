import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { PlaceTranslationResponse } from '@/services/places/place-search-translate.types';

type AirportsInfoInitialState = {
  airportsInfo: PlaceTranslationResponse[] | null;
};

const initialState: AirportsInfoInitialState = {
  airportsInfo: null,
};
export const airportsInfoSlice = createSlice({
  name: 'airportsInfo',
  initialState,
  reducers: {
    airportsInfoSave: (state, action: PayloadAction<PlaceTranslationResponse[]>) => {
      if (state.airportsInfo === null) {
        state.airportsInfo = Array.from(new Set(action.payload));
      } else {
        state.airportsInfo = Array.from(new Set(state.airportsInfo.concat(action.payload)));
      }
    },
    airportsInfoClear: state => {
      state.airportsInfo = null;
    },
  },
});

export const { airportsInfoSave, airportsInfoClear } = airportsInfoSlice.actions;

export default airportsInfoSlice.reducer;
