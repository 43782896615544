import type { FooterMenu, SocialsItem } from '@/components/common/footer/footer.types';
import type { AutocompleteOptions } from '@/components/common/responsive-autocomplete/view/autocomplete-view/autocomplete-view.types';
import { Routes } from '@/components/common/routes/routes';

export const BOOKING_URL = process.env.NEXT_PUBLIC_BOOKING_SITE_URL;
export const VISIT_OLD_URL = process.env.NEXT_PUBLIC_VOI_OLD_SITE_URL;
export const VERSION = '1.5.0';

export enum Visa {
  TOURIST = 'TOURIST',
  PILGRIMAGE = 'PILGRIMAGE',
  BUSINESS = 'BUSINESS',
}

export enum Marital {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export const importantLinks: FooterMenu = {
  title: 'Important links',
  items: [
    { title: 'Flight', url: Routes.Flight },
    { title: 'Hotel', url: Routes.Hotel },
    { title: 'About US', url: VISIT_OLD_URL + Routes.AboutUs },
    { title: 'Contact US', url: VISIT_OLD_URL + Routes.ContactUs },
    { title: 'Blog', url: VISIT_OLD_URL + Routes.Blog },
    { title: 'Diaries', url: VISIT_OLD_URL + Routes.Diaries },
  ],
};

export const services: FooterMenu = {
  title: 'Services',
  items: [
    { title: 'Visa', url: VISIT_OLD_URL + '/iran-visa' },
    { title: 'Insurance', url: VISIT_OLD_URL + '/iran-travel-insurance' },
    { title: 'Debit Card', url: VISIT_OLD_URL + '/iran-travel-debit-card' },
    { title: 'Transfer', url: VISIT_OLD_URL + '/iran-transfer' },
    { title: 'Sim-Card', url: VISIT_OLD_URL + '/sim-card' },
    { title: 'Car-Rental', url: VISIT_OLD_URL + '/car-rental' },
  ],
};

export const socials: SocialsItem[] = [
  { icon: 'icon-instagram', url: Routes.Instagram, label: 'Visit Our Iran Page' },
  { icon: 'icon-twitter', url: Routes.Twitter, label: 'Visit Our Iran Profile' },
  {
    icon: 'icon-whatsapp',
    url: `https://wa.me/+${process.env.NEXT_PUBLIC_WHATSAPP_VISIT}?text=Hi,%20Can%20you%20help%20me%20?`,
    label: 'Visit Our Iran Whatsapp',
  },
  { icon: 'icon-youtube', url: Routes.Youtube, label: 'Visit Our Iran Channel' },
  { icon: 'icon-facebook', url: Routes.Facebook, label: 'Visit Our Iran Profile' },
];

export const citiesOptions: AutocompleteOptions[] = [
  {
    label: 'Tabriz',
    value: '1',
    ar_label: 'تبريز',
  },
  {
    label: 'Western Azerbaijan',
    value: '2',
    ar_label: 'أذربيجان الغربية',
  },
  {
    label: 'Ardabil',
    value: '3',
    ar_label: 'اردبيل',
  },
  {
    label: 'Esfahan',
    value: '4',
    ar_label: 'اصفهان',
  },
  {
    label: 'Alborz',
    value: '5',
    ar_label: 'البرز',
  },
  {
    label: 'Ilam',
    value: '6',
    ar_label: 'ايلام',
  },
  {
    label: 'Bushehr',
    value: '7',
    ar_label: 'بوشهر',
  },
  {
    label: 'Tehran',
    value: '8',
    ar_label: 'طهران',
  },
  {
    label: 'South Khorasan',
    value: '9',
    ar_label: 'خراسان جنوبي',
  },
  {
    label: 'Khorasan Razavi',
    value: '10',
    ar_label: 'خراسان رضوي',
  },
  {
    label: 'North Khorasan',
    value: '11',
    ar_label: 'خراسان شمالي',
  },
  {
    label: 'Khuzestan',
    value: '12',
    ar_label: 'خوزستان',
  },
  {
    label: 'Zanjan',
    value: '13',
    ar_label: 'زنجان',
  },
  {
    label: 'Semnan',
    value: '14',
    ar_label: 'سمنان',
  },
  {
    label: 'Sistan and Baluchistan',
    value: '15',
    ar_label: 'سيستان وبلوچستان',
  },
  {
    label: 'Shiraz',
    value: '16',
    ar_label: 'شيراز',
  },
  {
    label: 'Qazvin',
    value: '17',
    ar_label: 'قزوين',
  },
  {
    label: 'Qom',
    value: '18',
    ar_label: 'قم',
  },
  {
    label: 'Kurdistan',
    value: '19',
    ar_label: 'كردستان',
  },
  {
    label: 'Kerman',
    value: '20',
    ar_label: 'كرمان',
  },
  {
    label: 'Kermanshah',
    value: '21',
    ar_label: 'كرمانشاه',
  },
  {
    label: 'Kohgiloyeh Boyerahmad',
    value: '22',
    ar_label: 'كهگيلويه وبويراحمد',
  },
  {
    label: 'Golestan',
    value: '23',
    ar_label: 'گلستان',
  },
  {
    label: 'Gilan',
    value: '24',
    ar_label: 'گيلان',
  },
  {
    label: 'Lorestan',
    value: '25',
    ar_label: 'لرستان',
  },
  {
    label: 'Mazandaran',
    value: '26',
    ar_label: 'مازندران',
  },
  {
    label: 'Markazi',
    value: '27',
    ar_label: 'مركزي',
  },
  {
    label: 'Hormozgan',
    value: '28',
    ar_label: 'هرمزگان',
  },
  {
    label: 'Hamedan',
    value: '29',
    ar_label: 'همدان',
  },
  {
    label: 'Chaharmahal and Bakhtiari ',
    value: '30',
    ar_label: 'چهارمحال بختياري',
  },
  {
    label: 'Yazd',
    value: '31',
    ar_label: 'يزد',
  },
  {
    label: 'Kashan',
    value: '32',
    ar_label: 'كاشان',
  },
  {
    label: 'kish-island',
    value: '33',
    ar_label: 'جزيره كيش',
  },
];

export type languagesType = {
  value: string;
  title: string;
};

export const LANGUAGES: languagesType[] = [
  {
    value: 'en',
    title: 'English',
  },
  {
    value: 'ar',
    title: 'العربیه',
  },
];
