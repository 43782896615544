import type React from 'react';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { AlertColor } from '@mui/material';

type SnackbarInitialState = {
  value: string | React.ReactNode;
  show: boolean;
  severity: AlertColor;
  showButton?: boolean;
  textButton?: string;
  urlButton?: string;
  duration?: number;
};

type SnackbarAlertInitialState = {
  snackbar: SnackbarInitialState;
};

const initialState: SnackbarAlertInitialState = {
  snackbar: { value: '', show: false, severity: 'error', showButton: false },
};

export const snackbarAlertSlice = createSlice({
  name: 'snackbarAlert',
  initialState,
  reducers: {
    snackbarAlertSave: (state, action: PayloadAction<SnackbarInitialState>) => {
      state.snackbar = action.payload;
    },
  },
});

export const { snackbarAlertSave } = snackbarAlertSlice.actions;

export default snackbarAlertSlice.reducer;
