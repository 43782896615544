import { Routes } from '@/components/common/routes/routes';
import palette from '@/components/common/styles/color.module.scss';
import { VISIT_OLD_URL } from '@/components/common/constant/constant';
import type { NextSeoProps } from 'next-seo/lib/types';

type Config = {
  ISR?: {
    travelPackService?: number;
    blogWordpress?: number;
  };
  redirectTime?: number;
  seo: {
    default: NextSeoProps;
    travelPack: NextSeoProps;
    travelPackInformation: NextSeoProps;
    [key: string]: NextSeoProps;
  };
};

const config: Config = {
  ISR: {
    travelPackService: 3600,
    blogWordpress: 3600,
  },
  redirectTime: 5,
  seo: {
    default: {
      title: 'Discover and Book Amazing Tours and Activities in Iran - Visit Our Iran',
      description: 'VisitOurIran is an app to book hotel and reserve air plane ticket',
      themeColor: palette.primary,
      canonical: `${VISIT_OLD_URL}`,
      openGraph: {
        type: 'website',
        title: 'VisitOurIran',
        description: 'VisitOurIran is an app to book hotel and reserve air plane ticket',
        siteName: 'VisitOurIran',
        url: `${VISIT_OLD_URL}`,
        images: [{ url: '/og-image.webp' }],
      },
      additionalLinkTags: [
        {
          rel: 'shortcut icon',
          href: '/favicon.ico',
        },
      ],
      additionalMetaTags: [
        {
          name: 'viewport',
          content: 'minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no',
        },
      ],
    },
    travelPack: {
      title: 'Travel Pack',
      description: 'Travel Pack Description',
      canonical: `${VISIT_OLD_URL + Routes.TravelersService}`,
      openGraph: {
        title: 'Travel Pack',
        description: 'Travel Pack Description',
        url: `${VISIT_OLD_URL}${Routes.TravelersService}`,
        images: [{ url: '/og-travel-pack.webp' }],
      },
    },
    travelPackInformation: {
      nofollow: true,
      noindex: true,
    },
  },
};

export default config;
