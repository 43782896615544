import { configureStore } from '@reduxjs/toolkit';
import exchangeRateReducer from './exchange-rate-slice';
import airlinesInfoReducer from './airlines-info-slice';
import airportsInfoReducer from './airports-info-slice';
import snackbarAlertReducer from './snackbar-alert-slice';
import authReducer from '@travel-platform/commons/src/redux/auth-slice';
import travelersReducer from '@/redux/travelers-slice';
import countriesReducer from './countries-slice';
import passengersFlightReducer from './passengers-flight-slice';
import cartReducer from './cart-slice';
import loginReducer from '@travel-platform/commons/src/redux/login-slice';
import HotelSearchHistoryReducer from '@travel-platform/commons/src/redux/hotel-search-history-slice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const HotelSearchHistoryLocalStorage = {
  key: 'searchHistory_hotel',
  storage,
};

export const store = configureStore({
  reducer: {
    snackbarAlert: snackbarAlertReducer,
    login: loginReducer,
    auth: authReducer,
    exchangeRate: exchangeRateReducer,
    airlinesInfo: airlinesInfoReducer,
    airportsInfo: airportsInfoReducer,
    travelers: travelersReducer,
    countries: countriesReducer,
    passengersFlight: passengersFlightReducer,
    cart: cartReducer,
    HotelSearchHistory: persistReducer(HotelSearchHistoryLocalStorage, HotelSearchHistoryReducer),
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
