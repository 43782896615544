import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { GetExchangeRatesResponse } from '@/services/payment/payment.types';

type ExchangeRateInitialState = {
  exchangeRate: {
    rates: GetExchangeRatesResponse | null;
    expireDate: number | null;
    loadingDate: number | null;
  };
};

const initialState: ExchangeRateInitialState = {
  exchangeRate: {
    rates: null,
    expireDate: null,
    loadingDate: null,
  },
};

export const exchangeRateSlice = createSlice({
  name: 'exchangeRate',
  initialState,
  reducers: {
    exchangeRateSaveRates: (state, action: PayloadAction<GetExchangeRatesResponse>) => {
      state.exchangeRate.rates = action.payload;
    },
    exchangeRateSetLoadingDate: (state, action: PayloadAction<number>) => {
      state.exchangeRate.loadingDate = action.payload;
    },
    exchangeRateSetExpireDate: (state, action: PayloadAction<number>) => {
      state.exchangeRate.expireDate = action.payload;
    },
    exchangeRateClear: state => {
      state.exchangeRate.rates = null;
    },
  },
});

export const {
  exchangeRateSaveRates,
  exchangeRateClear,
  exchangeRateSetLoadingDate,
  exchangeRateSetExpireDate,
} = exchangeRateSlice.actions;

export default exchangeRateSlice.reducer;
