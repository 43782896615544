import { Box } from '@mui/material';
import Link from 'next/link';
import styles from './whatsapp-messenger.module.scss';

export default function WhatsappMessenger() {
  return (
    <Box className={styles.whatsapp + ' whatsapp'}>
      <Link
        href={`https://wa.me/+${process.env.NEXT_PUBLIC_WHATSAPP_VISIT}?text=Hi,%20Can%20you%20help%20me%20?`}>
        <Box
          sx={{
            color: 'white',
            backgroundColor: '#30BF39',
            position: 'relative',
            width: '50px',
            height: '50px',
            borderRadius: 9999,
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.16)',
            '&:hover': {
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
            },
          }}>
          <Box
            sx={{
              top: '50%',
              left: '50%',
              transform: ' translate(-50%,-50%)',
              position: 'absolute',
              fontSize: 28,
            }}
            className='icon-whatsapp'></Box>
        </Box>
      </Link>
    </Box>
  );
}
