import { useMediaQuery, useTheme } from '@mui/material';
import { useRouter } from 'next/router';

export enum DeviceEnum {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}
export default function useDevice() {
  const theme = useTheme();
  const router = useRouter();

  const isMobile =
    useMediaQuery(theme.breakpoints.down('sm')) || router.pathname.includes('/mobile');
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  if (isMobile) return DeviceEnum.MOBILE;
  else if (isDesktop) return DeviceEnum.DESKTOP;
  else return DeviceEnum.TABLET;
}
