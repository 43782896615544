import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { AirlineTranslateResponse } from '@/services/places/place-search-translate.types';

type AirlineInfoInitialState = {
  airlinesInfo: AirlineTranslateResponse[] | null;
};

const initialState: AirlineInfoInitialState = {
  airlinesInfo: null,
};

export const airlinesInfoSlice = createSlice({
  name: 'airlinesInfo',
  initialState,
  reducers: {
    airlinesInfoSave: (state, action: PayloadAction<AirlineTranslateResponse[]>) => {
      if (state.airlinesInfo === null) {
        state.airlinesInfo = Array.from(new Set(action.payload));
      } else {
        state.airlinesInfo = Array.from(new Set(state.airlinesInfo.concat(action.payload)));
      }
    },
    airlinesInfoClear: state => {
      state.airlinesInfo = null;
    },
  },
});

export const { airlinesInfoSave, airlinesInfoClear } = airlinesInfoSlice.actions;

export default airlinesInfoSlice.reducer;
