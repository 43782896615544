import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { GetCountriesResponse } from '@/services/flights/flight.types';

type CountriesInitialState = {
  countries: GetCountriesResponse[] | null;
};

const initialState: CountriesInitialState = {
  countries: null,
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    countriesSave: (state, action: PayloadAction<GetCountriesResponse[]>) => {
      state.countries = action.payload;
    },
    countriesClear: state => {
      state.countries = null;
    },
  },
});

export const { countriesSave, countriesClear } = countriesSlice.actions;

export default countriesSlice.reducer;
