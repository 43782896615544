import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { TravelServiceState } from '@/components/travelers/package/package.types';
import type { FlightPassengerInformationStateType } from '@/services/authentication/authentication.types';
import {
  type CalculatePackDiscountResponseType,
  type GetTravelServiceResponseType,
  ServiceVariantDiscount,
} from '@/services/travel-service/travel-service.types';
import { type DebitCardTopUpResponseType } from '@/services/debit-card';

type TravelersInitialState = {
  travelPack: TravelServiceState[];
  passengers: FlightPassengerInformationStateType[];
  priceData: CalculatePackDiscountResponseType;
  services: GetTravelServiceResponseType[] | undefined;
  debitCardTopupData: DebitCardTopUpResponseType;
};

const initialState: TravelersInitialState = {
  travelPack: [],
  passengers: [],
  services: [],
  priceData: {
    discount: 0,
    type: ServiceVariantDiscount.PERCENTAGE,
    totalPrice: 0,
  },
  debitCardTopupData: {} as DebitCardTopUpResponseType,
};
export const travelersSlice = createSlice({
  name: 'travelers',
  initialState,
  reducers: {
    setTravelPack: (state, action: PayloadAction<TravelServiceState[]>) => {
      return { ...state, travelPack: action.payload };
    },
    setPassengers: (state, action: PayloadAction<FlightPassengerInformationStateType[]>) => {
      return { ...state, passengers: action.payload };
    },
    setDiscount: (state, action: PayloadAction<CalculatePackDiscountResponseType>) => {
      return { ...state, priceData: action.payload };
    },
    setServices: (state, action: PayloadAction<GetTravelServiceResponseType[]>) => {
      return { ...state, services: action.payload };
    },
    setDebitCardTopupData: (state, action: PayloadAction<DebitCardTopUpResponseType>) => {
      return { ...state, debitCardTopupData: action.payload };
    },
  },
});
export const { setTravelPack, setPassengers, setDiscount, setServices, setDebitCardTopupData } =
  travelersSlice.actions;
export default travelersSlice.reducer;
