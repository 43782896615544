import type { TravelServiceCartItemType } from '@/components/travelers/package/package.types';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

type CartType = {
  ServiceCart: TravelServiceCartItemType[];
};

const initialState: CartType = {
  ServiceCart: [],
};

export const EmptyServiceCartObject: TravelServiceCartItemType = {
  fakeId: '0',
  id: 0,
  name: undefined,
  price: 0,
  description: undefined,
  imageUrl: null,
  travelServiceId: 0,
  variantAttributes: null,
  unitPrice: 0,
  numberInputValue: 0,
  quantity: 0,
  variantFields: null,
};

export const CartSlice = createSlice({
  name: 'Cart',
  initialState,
  reducers: {
    addServiceCart: (state, action: PayloadAction<TravelServiceCartItemType>) => {
      state.ServiceCart = [...state.ServiceCart, action.payload];
    },
    updateServiceCartItem: (state, action: PayloadAction<TravelServiceCartItemType>) => {
      const { fakeId } = action.payload;
      const index = state.ServiceCart.findIndex(item => item.fakeId === fakeId);
      if (index !== -1) {
        state.ServiceCart[index] = { ...state.ServiceCart[index], ...action.payload };
      }
    },
    deleteServiceCartItem: (state, action: PayloadAction<string>) => {
      state.ServiceCart = state.ServiceCart.filter(item => item.fakeId !== action.payload);
    },
    clearServiceCart: state => {
      state.ServiceCart = [];
    },
  },
});

export const { addServiceCart, updateServiceCartItem, deleteServiceCartItem, clearServiceCart } =
  CartSlice.actions;

export default CartSlice.reducer;
