import React from 'react';
import Link from 'next/link';
import { Box, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useAppSelector } from '@/redux/hooks';

import palette from '@/components/common/styles/color.module.scss';

export type NavbarItemProps = {
  url: string;
  text: string;
  icon: string;
  needsLogin?: boolean;
  showLoginModal?: () => void;
};

export const NavbarItem: React.FC<NavbarItemProps> = (props): React.ReactNode => {
  // Destructure Props
  const { url, text, icon, needsLogin, showLoginModal }: NavbarItemProps = props;

  // Global State
  const { auth } = useAppSelector(state => state.auth);

  // Use Hooks
  const router = useRouter();

  // Constant
  const isActive = router.pathname === url;
  const color = isActive ? palette.primary : palette.black;

  // Functions
  const navigate = () => {
    if (needsLogin && !auth?.token) {
      showLoginModal?.();
    }
  };

  // Components
  const ChildItem = () => (
    <Stack
      alignItems='center'
      justifyItems='center'>
      <Box
        fontSize={24}
        color={color}
        className={icon}
      />
      <Typography
        variant='c2_regular'
        color={color}>
        {text}
      </Typography>
    </Stack>
  );

  return (
    <Box
      sx={{ cursor: 'pointer' }}
      onClick={!auth?.token && needsLogin ? navigate : undefined}>
      {!auth?.token && needsLogin ? (
        <ChildItem />
      ) : (
        <Link href={url}>
          <ChildItem />
        </Link>
      )}
    </Box>
  );
};
